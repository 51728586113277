import React from "react";
import styles from "./Grid.module.css";

export const Grid = ({columns, children}) => {
  return (
    <div className={styles.Grid}>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return (
            <div className={styles.Item} style={{width: `${Math.floor(100/columns)}%`}}>
              {child}
            </div>
          );
        }
      })}
    </div>
  )
}
