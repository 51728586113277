import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleInfo, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import styles from "./Alert.module.css";

const SEVERITY_CONTAINER_STYLE_MAP = {
  "success": styles.AlertSuccess,
  "info": styles.AlertInfo,
  "warning": styles.AlertWarning,
  "error": styles.AlertError,
}

const SEVERITY_ICON_STYLE_MAP = {
  "success": styles.IconSuccess,
  "info": styles.IconInfo,
  "warning": styles.IconWarning,
  "error": styles.IconError,
}

const SEVERITY_ICON_MAP = {
  "success": faCircleCheck,
  "info": faCircleInfo,
  "warning": faTriangleExclamation,
  "error": faTriangleExclamation,
}

export const Alert = ({severity, children}) => {
  return (
    <div className={SEVERITY_CONTAINER_STYLE_MAP[severity] || styles.Alert}>
      <div className={SEVERITY_ICON_STYLE_MAP[severity]}>
        <FontAwesomeIcon icon={SEVERITY_ICON_MAP[severity]} />
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}
