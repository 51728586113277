import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Container, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable } from "array-move";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import LinearProgress from '@mui/material/LinearProgress';
import { Alert } from "../widgets";
import styles from './EditContentPage.module.css';
import draftConfig from './DraftJSToolbarConfig';
import { Heading } from "../widgets";
import { useSnackbar } from 'notistack';
import ContentBlock from './ContentBlock';

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        {`${Math.round(
          props.value,
        )}%`}
      </Box>
    </Box>
  );
}

function AddDataDialog({ open, onClose, onSave }) {
  const [dataType, setDataType] = useState("");

  const handleSave = () => {
    onSave({
      id: uuidv4(),
      type: dataType,
    });
    onClose();
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Add Section
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ py: 2 }} spacing={2} direction="column">
            <FormControl fullWidth>
              <InputLabel id="add-new-data-type-label">Type</InputLabel>
              <Select
                labelId="add-new-data-type-label"
                id="add-new-data-type"
                label="Type"
                value={dataType}
                onChange={(e) => setDataType(e.target.value)}
              >
                <MenuItem value="IMAGE">Image</MenuItem>
                <MenuItem value="TEXT">Text</MenuItem>
                <MenuItem value="SECTION_BREAK">Section Break</MenuItem>
                <MenuItem value="FORM">Form</MenuItem>
                <MenuItem value="PAGE_LINK">Page Link</MenuItem>
                <MenuItem value="QUESTION">Question and Answer (FAQ)</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>
            Add Section
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function EditTextData({ data, onSave, onDelete }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isEditorDirty, setIsEditorDirty] = useState(false);

  useEffect(() => {
    if (data.html) {
      const blocksFromHtml = htmlToDraft(data.html);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const loadedEditorState = EditorState.createWithContent(contentState);
      setEditorState(loadedEditorState);
    }
  }, []);

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleSave = () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (html) {
      onSave({
        ...data,
        html: html,
      });
      setIsEditorDirty(false);
    }
  }

  const handleDelete = () => {
    onDelete(data);
  }

  const handleOnChange = () => {
    setIsEditorDirty(true);
  }

  return (
    <>
      <Editor
        editorState={editorState}
        wrapperClassName={styles.WrapperClass}
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        onEditorStateChange={handleEditorStateChange}
        onChange={handleOnChange}
        toolbar={draftConfig}
      />
      {isEditorDirty
        ? <Alert severity="warning">You have unsaved changes.</Alert>
        : null}
      <div></div>
      <Stack spacing={2} direction="row">
        <Button
          variant="contained"
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Stack>
    </>
  );
}




export default function EditContentPage({ wedding, page, updatePage, setPage, forms, pages }) {
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState();
  const [isAddDataDialogOpen, setIsAddDataDialogOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleAddDataDialogOpen = () => {
    setIsAddDataDialogOpen(true);
  }

  const handleAddDataDialogClose = () => {
    setIsAddDataDialogOpen(false);
  }

  const handleDataSave = async (newData) => {
    let nextData = [];

    let found = false;
    page?.data?.forEach((pageData) => {
      if (newData.id && newData.id === pageData.id) {
        found = true;
        nextData.push(newData);
      } else {
        nextData.push(pageData);
      }
    });

    // If we didn't find it it must be a new section so just put it on the end.
    if (!found) {
      nextData.push(newData);
    }

    setPage({
      ...page,
      data: nextData,
    })

    await updatePage(page.docId, page.title, page.published, nextData);

    enqueueSnackbar('Successfully saved!', {
      variant: 'success',
    });
  }

  const handleDataDeleteConfirm = (deleteData) => {
    setDataToDelete(deleteData);
    setIsDeleteConfirmDialogOpen(true);
  }

  const handleDataDeleteCancel = () => {
    setDataToDelete(undefined);
    setIsDeleteConfirmDialogOpen(false);
  }

  const handleDataDelete = async () => {
    let nextData = [];

    page.data.forEach((pageData) => {
      if (dataToDelete.id && dataToDelete.id !== pageData.id) {
        nextData.push(pageData);
      }
    })

    setPage({
      ...page,
      data: nextData,
    })

    setIsDeleteConfirmDialogOpen(false);
    await updatePage(page.docId, page.title, page.published, nextData);

    enqueueSnackbar('Successfully deleted!', {
      variant: 'success',
    });
  }

  const onDrop = async ({ removedIndex, addedIndex }) => {
    let newData = []
    page.data.forEach((data) => {
      newData.push(data);
    });

    newData = arrayMoveImmutable(newData, removedIndex, addedIndex)

    setPage({
      ...page,
      data: newData,
    });
    await updatePage(page.docId, page.title, page.published, newData);
  };

  return (
    <>
      <Heading level={1}>
        Content
      </Heading>

      {page?.data?.map((data, idx) => {
        return (
          <ContentBlock
            showMoveUp={idx !== 0}
            showMoveDown={idx !== page.data.length - 1}
            pageId={page.docId}
            wedding={wedding}
            data={data}
            pages={pages}
            forms={forms}
            />
        )
      })}



      {/* <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <List>
          <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
            {page?.data?.map((data) => {
              return (
                <Draggable key={data.id} className={styles.ContentBlock}>
                  <Box sx={{ py: 4 }}>
                    <ListItem disablePadding>
                      <ListItemIcon className="drag-handle">
                        <DragHandleIcon />
                      </ListItemIcon>
                      <Stack sx={{ width: "100%" }} spacing={2} direction="column">
                        {data.type === "TEXT"
                          ? <EditTextData data={data} onSave={handleDataSave} onDelete={handleDataDeleteConfirm} />
                          : null}
                        {data.type === "IMAGE"
                          ? <EditImageData data={data} onSave={handleDataSave} onDelete={handleDataDeleteConfirm} />
                          : null}
                        {data.type === "SECTION_BREAK"
                          ? <EditSectionBreakData data={data} onDelete={handleDataDeleteConfirm} />
                          : null}
                        {data.type === "FORM"
                          ? <EditFormRefData data={data} onSave={handleDataSave} onDelete={handleDataDeleteConfirm} forms={forms} />
                          : null}
                        {data.type === "PAGE_LINK"
                          ? <PageLink data={data} onSave={handleDataSave} onDelete={handleDataDeleteConfirm} pages={pages.filter((thisPage) => thisPage.docId !== page.docId)} />
                          : null}
                        {data.type === "QUESTION"
                          ? <QuestionAndAnswer data={data} onSave={handleDataSave} onDelete={handleDataDeleteConfirm} />
                          : null}
                      </Stack>
                    </ListItem>
                  </Box>
                </Draggable>
              )
            })}
          </Container>
        </List>
        <Button onClick={handleAddDataDialogOpen}>
          Add a Section
        </Button>
      </Box> */}

      <Dialog
        open={isDeleteConfirmDialogOpen}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this content?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDataDeleteCancel}>No</Button>
          <Button onClick={handleDataDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <AddDataDialog
        open={isAddDataDialogOpen}
        onClose={handleAddDataDialogClose}
        onSave={handleDataSave}
      />

    </>
  )
}
