import * as React from "react";
import styles from "./Tabs.module.css";

export const TabSelectorList = ({children, onChange, selectedTabKey}) => {
  if (!selectedTabKey) return;
  const tabSelectors = React.Children.map(children, child => {
    if (React.isValidElement(child) && child.type.name === TabSelector.name) {
      const childProps = {
        ...child.props,
        isSelected: child.props.tabKey === selectedTabKey,
        onChange: () => onChange(child.props.tabKey),
      }

      return React.cloneElement(child, childProps);
    }
  });

  let tabActions;
  React.Children.forEach(children, child => {
    if (React.isValidElement(child) && child.type.name === TabSelectorActionList.name && (child.props.tabKey === selectedTabKey || child.props.tabKey === undefined)) {
      tabActions = child;
    }
  });

  return (
    <div className={styles.TabSelectorListContainer}>
      <div className={styles.TabSelectorList}>
        {tabSelectors}
      </div>
      <div className={styles.TabSelectorActions}>
        {tabActions}
      </div>
    </div>
  )
}

export const TabSelector = ({tabKey, isSelected, children, onChange}) => {
  return (
    <div key={tabKey} onClick={onChange} className={isSelected ? styles.TabSelectorSelected : styles.TabSelector}>
      {children}
    </div>
  )
}

export const TabSelectorActionList = ({children}) => {
  return (
    <div className={styles.TabSelectorActionList}>
      {children}
    </div>
  );
}

export const TabContentList = ({children, selectedTabKey}) => {
  if (!selectedTabKey) return;
  let visibleTab;
  React.Children.forEach(children, child => {
    if (child.props.tabKey === selectedTabKey) {
      visibleTab = child;
    }
  });
  return visibleTab;
}

export const TabContent = ({tabKey, children}) => {
  return (
    <>{children}</>
  );
}
