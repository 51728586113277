import * as React from "react";
import styles from "./ContentTitle.module.css";

function ContentTitle({title}) {
  return (
    <header className={styles.ContentTitle}>
      {title}
    </header>
  );
}

export default ContentTitle;
