import * as React from "react";
import styles from "./Checkbox.module.css";

export const Checkbox = ({checked, onChange}) => {
  return (
    <input
      className={styles.Checkbox}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      />
  );
}
