import * as React from "react";
import { Outlet, useLocation } from "react-router-dom"
import Nav from "../components/layouts/Nav";
import ContentTitle from "../components/layouts/ContentTitle";
import styles from "./Main.module.css";

function Main({getRouteTitle}) {
  const location = useLocation();

  return (
    <div className={styles.Main}>
      <div className={styles.Nav}>
        <Nav selectedPathname={location.pathname} />
      </div>
      <div className={styles.Content}>
        <div className={styles.Header}>
          <ContentTitle title={getRouteTitle(location)} />
        </div>
        <div className={styles.Body}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Main;
