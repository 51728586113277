import React from "react";
import { Modal, ModalActionList } from "./Modal";
import { Button } from "./Button";
import { faCheck, faXmark, faQuestion } from "@fortawesome/free-solid-svg-icons";

export const ModalConfirm = ({isVisible, title, description, confirmLabel, onCancelClick, cancelLabel, onConfirmClick}) => {
  return (
    <Modal
        isVisible={isVisible}
        icon={faQuestion}
        title={title}
        description={description}
    >
      <ModalActionList>
        <Button
          icon={faXmark}
          onClick={onCancelClick}
          >
          {cancelLabel || "No"}
        </Button>
        <Button
          icon={faCheck}
          onClick={onConfirmClick}
          >
          {confirmLabel || "Yes"}
        </Button>
      </ModalActionList>
    </Modal>
  )
}
