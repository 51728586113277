import React, { useState, useMemo } from 'react';
import { Button, Heading, TabSelectorList, TabSelector, TabContentList, TabContent, TabSelectorActionList, TextInput } from "../widgets";
import ImportGuestListDialog from './ImportGuestListDialog';
import HouseholdDialog from './HouseholdDialog';
import ContactGuestsDialog from './ContactGuestsDialog';
import ContactHistoryDialog from './ContactHistoryDialog';
import GuestListRecentActivity from './GuestListRecentActivity';
import { uuid4 } from "../../utils";
import { faUserPlus, faFileImport, faPaperPlane, faSearch } from '@fortawesome/free-solid-svg-icons'
import GuestListGuestsTable from './GuestListGuestsTable';
import styles from "./GuestList.module.css";

const DEFAULT_NEW_HOUSEHOLD = { guests: [{ firstName: "", lastName: "", phone: "", email: "" }] };

const getNewHousehold = () => {
  const newHousehold = { ...DEFAULT_NEW_HOUSEHOLD };
  newHousehold.guests[0].id = uuid4();
  return newHousehold;
}

const getSelectedHouseholds = (allHouseholds, selectedHouseholdDocIds) => {
  if (selectedHouseholdDocIds) {
    return allHouseholds.filter((household) => household.docId in selectedHouseholdDocIds);
  } else {
    return [];
  }
}

const getHouseholdsForDisplay = (households, mail, messages) => {
  const householdsForDisplay = [];

  households.forEach(household => {
    let searchString = "";

    // When searching/filtering we search across many fields of each guest within a household, these
    // fields have some basic processing done to them. Rather than having to compute this each time
    // we do a search, we can combine all the potential fields we want to search on into a single
    // string and then just do a simple indexOf on this string later to avoid the nested loops
    // computation.
    household.guests.forEach(guest => {
      searchString += guest.firstName.toLowerCase();
      searchString += guest.lastName.toLowerCase();
      searchString += guest.email.toLowerCase();
      searchString += guest.phone.toLowerCase();
      searchString += `0${guest.phone.substr(3).toLowerCase()}`;
    });

    // Gather all the mail and messages for this household and well as determining any with errors.
    const householdMail = mail?.filter((thisMail) => thisMail.householdDocId === household.docId);
    const householdMessages = messages?.filter((thisMessage) => thisMessage.householdDocId === household.docId);
    const unacknowledgedHouseholdMailWithErrors = householdMail?.find(thisMail => thisMail.delivery?.error && !thisMail.errorAcknowledged)
    const unacknowledgedHouseholdMessagesWithErrors = householdMessages?.find(thisMessage => thisMessage.delivery?.errorCode && !thisMessage.errorAcknowledged);

    householdsForDisplay.push({
      ...household,
      // Add the search string to the household so we can use it later
      searchString: searchString,
      // Mixin the mail and messages so we don't have to keep looking these up
      mail: householdMail,
      hasMailBeenSent: !!householdMail,
      hasMailWithUnacknowledgedErrors: !!unacknowledgedHouseholdMailWithErrors,
      messages: householdMessages,
      hasMessageBeenSent: !!householdMessages,
      hasMessagesWithUnacknowledgedErrors: !!unacknowledgedHouseholdMessagesWithErrors,
    });

    // The sorting will never change so we can do this here before we do filtering/searching.
    householdsForDisplay.sort((a, b) => {
      // Sort by the last name of the first guest of each household.
      return a.guests[0]?.lastName.toLowerCase() > b.guests[0]?.lastName.toLowerCase() ? 1 : -1;
    });
  });

  return householdsForDisplay;
}

const getSearchFilteredHouseholdsForDisplay = (householdsForDisplay, searchTerm) => {
  const tidiedSearchTerm = searchTerm.toLowerCase().trim();
  const phoneSearchTerm = searchTerm.replace(/\s/g, '');

  return householdsForDisplay.filter(household => {
    return household.searchString.indexOf(tidiedSearchTerm) >= 0 || household.searchString.toLowerCase().indexOf(phoneSearchTerm) >= 0;
  });
}

export default function GuestList({ households, addHousehold, updateHousehold, fetchHouseholds, deleteHousehold, websiteUrl, columns, mail, messages, onErrorAcknowledge, recentActivity }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [isCreateHouseholdOpen, setIsCreateHouseholdOpen] = useState(false);
  const [isContactGuestsOpen, setIsContactGuestsOpen] = useState(false);
  const [newHousehold, setNewHousehold] = useState(getNewHousehold());
  const [editHouseholdDocId, setEditHouseholdDocId] = useState();
  const [editHousehold, setEditHousehold] = useState();
  const [selectedHouseholdDocIds, setSelectedHouseholdDocIds] = useState({});
  const [isContactHistoryOpen, setIsContactHistoryOpen] = useState(false);
  const [contactHistoryHousehold, setContactHistoryHousehold] = useState(null);
  const [activeTab, setActiveTab] = useState("ALL_GUESTS");

  const selectedHouseholds = useMemo(() => getSelectedHouseholds(households, selectedHouseholdDocIds), [households, selectedHouseholdDocIds]);

  const handleCreateHouseholdOpen = () => {
    setIsCreateHouseholdOpen(true);
  }

  const handleCreateHouseholdClose = async () => {
    await fetchHouseholds();
    setNewHousehold(getNewHousehold());
    setIsCreateHouseholdOpen(false);
  }

  const handleEditHouseholdOpen = (docId) => {
    const household = households.find((household) => household.docId === docId);
    setEditHouseholdDocId(household.docId);
    setEditHousehold(household);
  }

  const handleEditHouseholdClose = async () => {
    await fetchHouseholds();
    setEditHousehold(undefined);
    setEditHouseholdDocId(undefined);
  }

  const handleImportClose = async () => {
    await fetchHouseholds();
    setIsImportOpen(false);
  }

  const handleToggleAllSelected = (e) => {
    if (e.target.checked) {
      const nextSelectedHouseholds = { ...selectedHouseholdDocIds };
      households.forEach((household) => { nextSelectedHouseholds[household.docId] = true });
      setSelectedHouseholdDocIds(nextSelectedHouseholds)
    } else {
      setSelectedHouseholdDocIds({});
    }
  }

  const handleToggleSelectedHousehold = (e, householdDocId) => {
    if (e.target.checked) {
      setSelectedHouseholdDocIds({ ...selectedHouseholdDocIds, [householdDocId]: true })
    } else {
      const nextSelectedHouseholds = { ...selectedHouseholdDocIds };
      delete nextSelectedHouseholds[householdDocId];
      setSelectedHouseholdDocIds(nextSelectedHouseholds);
    }
  }

  const householdsForDisplay = useMemo(() => getHouseholdsForDisplay(households, mail, messages), [households, mail, messages]);
  const filteredHouseholdsToDisplay = useMemo(() => getSearchFilteredHouseholdsForDisplay(householdsForDisplay, searchTerm), [householdsForDisplay, searchTerm]);

  return (
    <>
      <Heading level={1}>
        Households
      </Heading>

      <TabSelectorList onChange={setActiveTab} selectedTabKey={activeTab}>
        <TabSelector tabKey="ALL_GUESTS">All Guests</TabSelector>
        <TabSelector tabKey="RECENT_ACTIVITY">Recent Activity</TabSelector>
        <TabSelectorActionList tabKey="ALL_GUESTS">
          <TextInput
            placeholder="Search..."
            icon={faSearch}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            containerClassName={styles.SearchTextInputContainer}
            />
          <Button
            onClick={handleCreateHouseholdOpen}
            icon={faUserPlus}
          >
            Add Guest
          </Button>
          <Button
            icon={faFileImport}
            onClick={() => setIsImportOpen(true)}
          >
            Import Guest List
          </Button>
          <Button
            icon={faPaperPlane}
            onClick={() => setIsContactGuestsOpen(true)}
            disabled={Object.keys(selectedHouseholdDocIds).length === 0}
          >
            Contact Guests
          </Button>
        </TabSelectorActionList>
      </TabSelectorList>

      <TabContentList selectedTabKey={activeTab}>
        <TabContent tabKey="ALL_GUESTS">
          <GuestListGuestsTable
            selectedHouseholdDocIds={selectedHouseholdDocIds}
            households={filteredHouseholdsToDisplay}
            columns={columns}
            onToggleAllSelected={handleToggleAllSelected}
            onToggleSelectedHousehold={handleToggleSelectedHousehold}
            onHouseholdClick={handleEditHouseholdOpen}
            />
        </TabContent>
        <TabContent tabKey="RECENT_ACTIVITY">
          <GuestListRecentActivity recentActivity={recentActivity} />
        </TabContent>
      </TabContentList>

      <ImportGuestListDialog open={isImportOpen} onClose={handleImportClose} addHousehold={addHousehold} />

      {/* New Household Dialog */}
      <HouseholdDialog
        open={isCreateHouseholdOpen}
        onClose={handleCreateHouseholdClose}
        mode={"NEW"}
        household={newHousehold}
        setHousehold={setNewHousehold}
        onSave={addHousehold}
        questions={columns}
      />

      {/* Edit Household Dialog */}
      <HouseholdDialog
        open={editHouseholdDocId !== undefined}
        onClose={handleEditHouseholdClose}
        mode={"EDIT"}
        household={editHousehold}
        setHousehold={setEditHousehold}
        onSave={updateHousehold}
        onDelete={() => deleteHousehold(editHouseholdDocId)}
        websiteUrl={websiteUrl}
        questions={columns}
      />

      <ContactGuestsDialog
        open={isContactGuestsOpen}
        onClose={() => setIsContactGuestsOpen(false)}
        recipientHouseholds={selectedHouseholds}
        websiteUrl={websiteUrl}
      />

      <ContactHistoryDialog
        open={isContactHistoryOpen && contactHistoryHousehold}
        onClose={() => {
          setIsContactHistoryOpen(false);
          setContactHistoryHousehold(null);
        }}
        household={contactHistoryHousehold}
        mail={mail?.filter((thisMail) => thisMail.householdDocId === contactHistoryHousehold?.docId)}
        messages={messages?.filter((thisMessage) => thisMessage.householdDocId === contactHistoryHousehold?.docId)}
        onErrorAcknowledge={onErrorAcknowledge}
      />
    </>
  );
}
