import * as React from "react";
import styles from "./Table.module.css";

export const Table = ({children}) => {
  return (
    <table className={styles.Table}>{children}</table>
  );
}

export const TableHead = ({children}) => {
  return (
    <thead>{children}</thead>
  );
}

export const TableBody = ({children}) => {
  return (
    <tbody>{children}</tbody>
  );
}

export const TableRow = ({children}) => {
  return (
    <tr>{children}</tr>
  );
}

export const TableCell = ({children}) => {
  return (
    <td>{children}</td>
  );
}
