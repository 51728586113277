import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faArrowDown, faArrowUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { Alert, Button, TextInput, HTMLEditor, DropDown } from "../widgets";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import draftConfig from './DraftJSToolbarConfig';
import styles from "./ContentBlock.module.css";

const ContentBlockTitle = ({data, pages, forms}) => {
  let displayName;
  let summary;
  console.log(forms);
  switch (data.type) {
    case "TEXT":
      displayName = "Text";
      summary = data.html.replace(/<\/?[^>]+(>|$)/g, "").replace(/\&.*;/g, '');
      break;
    case "IMAGE":
      displayName = "Image";
      summary = data.url.split('?')[0].split('%2F').pop();
      break;
    case "SECTION_BREAK":
      displayName = "Section Break";
      summary = "";
      break;
    case "FORM":
      displayName = "Form";
      summary = forms.find((thisForm) => thisForm.docId === data.formRef)?.name;
      break;
    case "PAGE_LINK":
      displayName = "Page Link";
      summary = pages.find((thisPage) => thisPage.docId === data.pageRef)?.title;
      break;
    case "QUESTION":
      displayName = "Question and Answer";
      summary = data.question;
      break;
  }

  return (
    <>
      {summary
        ? <div className={styles.Title}>{`${displayName} - `}</div>
        : <div className={styles.Title}>{displayName}</div>}
      {summary
        ? <div className={styles.TitleSummary}>{summary}</div>
        : null}
    </>
  );
}

const EditSectionBreakData = ({ data }) => {
  return (
    <>
      <Alert severity="info">Section Breaks display a simple line and no configuration is required.</Alert>
    </>
  );
}

const EditImageData = ({ weddingId, data, onSave }) => {
  const [uploadProgress, setUploadProgress] = useState();
  const [uploadStatus, setUploadStatus] = useState();

  function handleFile(file) {
    // Create the file metadata
    /** @type {any} */
    const metadata = {
      contentType: file.type,
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    const ext = file.name.split('.').pop();
    const storageRef = ref(storage, `uploads/${weddingId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
        setUploadStatus(snapshot.state);
      },
      (error) => {
        // TODO
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUploadProgress(undefined);
          setUploadStatus(undefined);

          onSave({
            ...data,
            url: downloadURL,
          });
        });
      }
    );
  }

  return (
    <>
      {data.url
        ? <img src={data.url} width={400} />
        : null}

      {/* {uploadStatus === "running"
        ? <LinearProgressWithLabel value={uploadProgress} />
        : null} */}
      <div>
        {data.url ? "Replace file: " : null}
        <input
          type="file"
          accept="image/*"
          onChange={(e) => { handleFile(e.target.files[0]) }}
        />
      </div>
    </>
  );
}

const PageLink = ({ data, onSave, pages }) => {
  const [pageRef, setPageRef] = useState(data.pageRef);
  const [title, setTitle] = useState(data.title || "");

  const handleSave = () => {
    onSave({
      ...data,
      pageRef: pageRef,
      title: title,
    });
  }

  return (
    <>
      <DropDown
        label="Page"
        value={pageRef}
        options={pages?.map(page => {
          return {
            value: page.docId,
            label: page.title,
          }
        })}
        onChange={(e) => setPageRef(e.target.value)}
        />
    <TextInput
      label="Link Title (optional)"
      value={title}
      onChange={(e) => setTitle(e.target.value)}
      variant="outlined"
    />
    {pageRef !== data.pageRef || title !== data.title
        ? <Alert severity="warning">You have unsaved changes.</Alert>
        : null}
    <Button
      variant="contained"
      onClick={handleSave}
    >
      Save
    </Button>
    </>
  );
}

const EditFormRefData = ({ data, onSave, onDelete, forms }) => {
  const [formRef, setFormRef] = useState(data.formRef);

  const handleSave = () => {
    onSave({
      ...data,
      formRef: formRef,
    });
  }

  return (
    <>
      <DropDown
        label="Form"
        value={formRef}
        options={forms?.map(form => {
          return {
            value: form.docId,
            label: form.name
          }
        })}
        onChange={(e) => setFormRef(e.target.value)}
        />
      {formRef !== data.formRef
        ? <Alert severity="warning">You have unsaved changes.</Alert>
        : null}
      <Button
        variant="contained"
        onClick={handleSave}
      >
        Save
      </Button>
    </>
  );
}

const QuestionAndAnswer = ({ data, onSave }) => {
  const [question, setQuestion] = useState(data.question);
  const [html, setHtml] = useState(data.html);

  const handleSave = () => {
    if (html) {
      onSave({
        ...data,
        question: question,
        answer: html,
      });
    }
  }

  const handleOnAnswerChange = (html) => {
    setHtml(html);
  }

  return (
    <>
      <TextInput
        label="Question"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        variant="outlined"
      />
      <HTMLEditor
        html={data.answer}
        onChange={handleOnAnswerChange}
        label="Answer"
        />
      {html !== data.html
        ? <Alert severity="warning">You have unsaved changes.</Alert>
        : null}
      <Button
        variant="contained"
        onClick={handleSave}
      >
        Save
      </Button>
    </>
  );
}

const EditTextData = ({ data, onSave }) => {
  const [html, setHtml] = useState(data.html);

  const handleSave = () => {
    if (html) {
      onSave({
        ...data,
        html: html,
      });
    }
  }

  const handleOnChange = (html) => {
    setHtml(html);
  }

  return (
    <>
      <HTMLEditor
        html={data.html}
        onChange={handleOnChange}
        />
      {html !== data.html
        ? <Alert severity="warning">You have unsaved changes.</Alert>
        : null}
      <Button
        variant="contained"
        onClick={handleSave}
      >
        Save
      </Button>
    </>
  );
}

const ContentBlock = ({wedding, data, pages, pageId, forms, showMoveDown, showMoveUp}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={styles.ContentBlock}>
      <div className={styles.ToolBar} role="button" onClick={() => setIsVisible(!isVisible)}>
        <div className={styles.VisibleToggle}>
          <FontAwesomeIcon icon={isVisible ? faCaretUp : faCaretDown} />
        </div>
        <div className={styles.ContentTitle}>
          <ContentBlockTitle data={data} pages={pages} pageId={pageId} forms={forms} />
        </div>
        <div className={styles.Actions}>
          {showMoveDown
            ? <div className={styles.Action}>
                <FontAwesomeIcon icon={faArrowDown} />
              </div>
            : null}
          {showMoveUp
            ? <div className={styles.Action}>
                <FontAwesomeIcon icon={faArrowUp} />
              </div>
            : null}
          <div className={styles.Action}>
            <FontAwesomeIcon icon={faTrash} />
          </div>
        </div>
      </div>
      {isVisible
        ? <div className={styles.Content}>
            {data.type === "IMAGE"
              // ? <EditImageData data={data} onSave={handleDataSave} />
              ? <EditImageData data={data} weddingId={wedding.id} />
              : null}
            {data.type === "SECTION_BREAK"
              ? <EditSectionBreakData data={data} />
              : null}
            {data.type === "PAGE_LINK"
              // ? <PageLink data={data} onSave={handleDataSave} pages={pages.filter((thisPage) => thisPage.docId !== page.docId)} />
              ? <PageLink data={data} pages={pages.filter((thisPage) => thisPage.docId !== pageId)} />
              : null}
            {data.type === "FORM"
              // ? <EditFormRefData data={data} onSave={handleDataSave} forms={forms} />
              ? <EditFormRefData data={data} forms={forms} />
              : null}
            {data.type === "QUESTION"
              // ? <QuestionAndAnswer data={data} onSave={handleDataSave} onDelete={handleDataDeleteConfirm} />
              ? <QuestionAndAnswer data={data} />
              : null}
            {data.type === "TEXT"
              // ? <EditTextData data={data} onSave={handleDataSave} onDelete={handleDataDeleteConfirm} />
              ? <EditTextData data={data} />
              : null}
          </div>
        : null}
    </div>
  );
}

export default ContentBlock;
