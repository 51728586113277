import React from "react";
import styles from "./RSVPBadge.module.css";

export const RSVPBadge = ({guestResponse}) => {
  console.log(guestResponse);
  if (guestResponse === true || guestResponse === false) {
    return (
      <span className={styles.BadgeContainer}>
        {guestResponse
          ? <span className={styles.Attending}>Attending</span>
          : <span className={styles.NotAttending}>Not Attending</span>}
      </span>
    );
  } else {
    return (
      <span className={styles.BadgeContainer}>
        <span className={styles.Invited}>Invited</span>
      </span>
    );
  }
}
