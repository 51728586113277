import React, { useState } from "react";
import styles from "./TruncatedText.module.css";

export const TruncatedText = ({width, maxChars, children}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const shortText = children.slice(0, maxChars);
  return (
    <div className={styles.TruncatedText} style={{width: width}}>
      <span>{isExpanded ? children : shortText}</span>
      {shortText !== children && !isExpanded
        ? <span
            role="button"
            className={styles.MoreButton}
            onClick={() => setIsExpanded(!isExpanded)}>...more</span>
        : null}
    </div>
  );
}
