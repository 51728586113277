import * as React from "react";
import styles from "./Unauthenticated.module.css";
import weddingImage from "./wedding-image.jpg";

function Unauthenticated({children}) {
  return (
    <div className={styles.Unauthenticated}>
      <div className={styles.Content}>
        {children}
      </div>
      <div className={styles.Image}>
        <img src={weddingImage} />
      </div>
    </div>
  );
}

export default Unauthenticated;
