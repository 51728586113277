import * as React from "react";
import styles from "./Heading.module.css";

export const Heading = ({level, children}) => {
  switch(level) {
    case 1:
      return <h1 className={styles.H1}>{children}</h1>;
    case 2:
      return <h2 className={styles.H2}>{children}</h2>;
    default:
      return <p>{children}</p>;
  }
}
