import React, { useState, useRef, useMemo, useCallback } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
// import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import draftConfig from '../website/DraftJSToolbarConfig';
import { useSnackbar } from 'notistack';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import styles from './ContactGuestDialog.module.css';
import { writeBatch, doc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import emailTemplate from "../../templates/email";
import smsTemplate from "../../templates/sms";

const SMS_MAX_LENGTH = 1600;

const mailMergeFields = [
  {
    name: "Guest Full Name (e.g. Joe Bloggs)",
    type: "GUEST_FULL_NAME",
  },
  {
    name: "Guest Name (e.g. Joe)",
    type: "GUEST_NAME",
  },
  {
    name: "Household Names (e.g. Joe and Jane)",
    type: "HOUSEHOLD_NAMES",
  },
  {
    name: "Household Website URL",
    type: "HOUSEHOLD_WEBSITE_URL",
  },
];

const EmailForm = ({ subject, body, onSubjectChange, onBodyChange }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(mailMergeFields);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin();
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    onBodyChange(raw);
  };

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({ value }) => {
    setSuggestions(defaultSuggestionsFilter(value, mailMergeFields));
  }, []);

  const onAddMention = () => {
    // get the mention object selected
  };

  return (
    <>
      <h3>Email Details</h3>
      <TextField
        value={subject}
        label="Subject"
        required
        variant="outlined"
        onChange={onSubjectChange}
      />

      <div
        className={styles.Editor}
        onClick={() => {
          ref.current?.focus();
        }}
      >
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onChange={handleEditorStateChange}
          toolbar={draftConfig}
          plugins={plugins}
          ref={ref}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
            // get the mention object selected
          }}
        />
      </div>
    </>
  )
}

const SMSForm = ({ body, onBodyChange }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(mailMergeFields);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin();
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    onBodyChange(raw);
  };

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({ value }) => {
    setSuggestions(defaultSuggestionsFilter(value, mailMergeFields));
  }, []);

  const onAddMention = () => {
    // get the mention object selected
  };

  return (
    <>
      <h3>SMS Details</h3>
      <div
        className={styles.Editor}
        onClick={() => {
          ref.current?.focus();
        }}
      >
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onChange={handleEditorStateChange}
          toolbar={draftConfig}
          plugins={plugins}
          ref={ref}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
            // get the mention object selected
          }}
        />
      </div>
    </>
  )
}

export default function ContactGuestsDialog({ open, onClose, recipientHouseholds, websiteUrl }) {
  const [messageType, setMessageType] = useState("email");
  const [recipientType, setRecipientType] = useState("guests");
  const [email, setEmail] = useState({ subject: "", body: "" });
  const [sms, setSms] = useState({ body: "" });
  const [isSendConfirmDialogOpen, setIsSendConfirmDialogOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirmSend = () => {
    setIsSendConfirmDialogOpen(true);
  }

  const getMailMergeValue = (format, type, household, guest) => {
    switch (type) {
      case "GUEST_FULL_NAME":
        if (guest.lastName) {
          return `${guest.firstName} ${guest.lastName}`;
        }
        return guest.firstName;
      case "GUEST_NAME":
        return guest.firstName;
      case "HOUSEHOLD_NAMES":
        if (household.guests.length === 1) {
          return guest.firstName;
        }
        let hnGuests = [];
        household.guests.forEach((guest) => {
          hnGuests.push(guest.firstName);
        });
        let hnLast = hnGuests.pop();
        return hnGuests.join(', ') + ' and ' + hnLast;
      case "HOUSEHOLD_FULL_NAMES":
        if (household.guests.length === 1) {
          return `${guest.firstName} ${guest.lastName}`;
        }
        let hfnGuests = [];
        household.guests.forEach((guest) => {
          if (guest.lastName) {
            hfnGuests.push(`${guest.firstName} ${guest.lastName}`);
          } else {
            hfnGuests.push(guest.firstName);
          }
        });
        let hfnLast = hfnGuests.pop();
        return hfnGuests.join(', ') + ' and ' + hfnLast;
      case "HOUSEHOLD_WEBSITE_URL":
        const url = `${websiteUrl}${household?.docId}`;
        if (format === "email") {
          return `<a href="${url}">${url}</a>`;
        } else if (format === "sms") {
          return url;
        }
    }
  }

  const mailMergeBody = (format, body, template, household, guest) => {
    let emailBodyHTML = "";
    for (const block of body.blocks) {
      let html = block.text;

      for (const entityRange of block.entityRanges) {
        const entityPlaceholder = block.text.substring(entityRange.offset, entityRange.offset + entityRange.length);
        const entity = body.entityMap[entityRange.key.toString()];
        html = html.replace(entityPlaceholder, getMailMergeValue(format, entity.data.mention.type, household, guest));
      }

      if (format === "email") {
        emailBodyHTML += `<p>${html}</p>`;
      } else if (format === "sms") {
        emailBodyHTML += `${html}\n`;
      }
    }


    return template.replace('{{{body}}}', emailBodyHTML);
  }

  const handleSend = async () => {
    setIsSendConfirmDialogOpen(false);
    setIsSending(true);

    const sendList = [];
    for (const household of recipientHouseholds) {
      for (const guest of household.guests) {
        if (messageType === "email") {
          if (guest.email) {
            sendList.push({
              householdDocId: household.docId,
              guestId: guest.id,
              weddingRef: "m-n-mwed",
              to: `${guest.firstName} ${guest.lastName} <${guest.email}>`,
              message: {
                subject: email.subject,
                html: mailMergeBody("email", email.body, emailTemplate, household, guest),
              }
            });
            // We just send to the first guest per household.
            if (recipientType === "households") {
              break;
            }
          }
        }
        if (messageType === "sms") {
          if (guest.phone) {
            sendList.push({
              householdDocId: household.docId,
              guestId: guest.id,
              weddingRef: "m-n-mwed",
              to: guest.phone,
              body: mailMergeBody("sms", sms.body, smsTemplate, household, guest).trim().substring(0, SMS_MAX_LENGTH),
            });
            // We just send to the first guest per household.
            if (recipientType === "households") {
              break;
            }
          }
        }

      }
    }

    const mailCollectionName = "mail";
    const smsCollectionName = "messages";

    let collectionName;
    if (messageType === "email") {
      collectionName = mailCollectionName;
    }
    if (messageType === "sms") {
      collectionName = smsCollectionName;
    }

    if (collectionName) {
      const batch = writeBatch(db);
      for (const mail of sendList) {
        const newMailRef = doc(collection(db, collectionName));
        batch.set(newMailRef, mail);
      }
      await batch.commit();
    }

    setIsSending(false);
    onClose();
    enqueueSnackbar(`Successfully sent ${messageType}!`, {
      variant: 'success',
    });
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Contact Guests
          {!isSending
            ? <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            : null}
        </DialogTitle>

        {isSending
          ? <DialogContent>
            <p>Sending messages, please wait...</p>
          </DialogContent>
          : <>
            <DialogContent>
              <FormControl>
                <FormLabel id="recipient-radio-buttons-group-label">Send an...</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="message-type-radio-buttons-group-label"
                  value={messageType}
                  onChange={(e) => setMessageType(e.target.value)}
                >
                  <FormControlLabel value="email" control={<Radio />} label="Email" />
                  <FormControlLabel value="sms" control={<Radio />} label="SMS" />
                </RadioGroup>
                <FormLabel id="recipient-radio-buttons-group-label">to...</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="recipient-radio-buttons-group-label"
                  value={recipientType}
                  onChange={(e) => setRecipientType(e.target.value)}
                >
                  <FormControlLabel value="guests" control={<Radio />} label="Guests" />
                  <FormControlLabel value="households" control={<Radio />} label="Households" />
                </RadioGroup>
              </FormControl>
              <Stack spacing={2} direction="column">
                {messageType === "email"
                  ? <EmailForm
                    subject={email.subject}
                    body={email.body}
                    onSubjectChange={(e) => {
                      setEmail({ ...email, subject: e.target.value });
                    }}
                    onBodyChange={(body) => {
                      setEmail({ ...email, body: body });
                    }}
                  />
                  : null}
                {messageType === "sms"
                  ? <SMSForm
                    body={sms.body}
                    onBodyChange={(body) => {
                      setSms({ body: body });
                    }}
                  />
                  : null}
              </Stack>
            </DialogContent>
            <DialogActions>
              {messageType === "email"
                ? <Button onClick={handleConfirmSend}>
                  {recipientType === "guests"
                    ? <>
                      Send to {recipientHouseholds?.reduce((count, current) => count + current.guests.filter((guest) => guest.email !== "").length, 0)} guests
                    </>
                    : <>
                      Send to {recipientHouseholds.length} households
                    </>}
                </Button>
                : <Button onClick={handleConfirmSend}>
                  {recipientType === "guests"
                    ? <>
                      Send to {recipientHouseholds?.reduce((count, current) => count + current.guests.filter((guest) => guest.phone !== "").length, 0)} guests
                    </>
                    : <>
                      Send to {recipientHouseholds.length} households
                    </>}
                </Button>}
            </DialogActions>
          </>}

      </Dialog>

      <Dialog
        open={isSendConfirmDialogOpen}
      >
        <DialogTitle id="alert-dialog-title">
          {recipientType === "guests"
            ? <>
              Are you sure you want to send to {recipientHouseholds?.reduce((count, current) => count + current.guests.filter((guest) => guest.email !== "").length, 0)} guests?
            </>
            : <>
              Are you sure you want to send to {recipientHouseholds.length} households?
            </>}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsSendConfirmDialogOpen(false)}>No</Button>
          <Button onClick={handleSend} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
