import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { getDoc, doc, updateDoc } from "firebase/firestore";


export const withAuthentication = Component => ({ ...props }) => {
  const [user, loading] = useAuthState(auth);
  const [wedding, setWedding] = useState();
  const navigate = useNavigate();

  const fetchUserData = async () => {
    // If we haven't yet got a user then we need to wait for auth before attempting to load the user data.
    // If we've already gotten both the user and the wedding data then there is no need to load again.
    if (!user || (user && wedding)) {
      return;
    }

    try {
      const userDoc = await getDoc(doc(db, "users", user?.uid));
      const data = userDoc.data();
      await fetchWedding(data.wedding);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  const fetchWedding = async (weddingId) => {
    if (!weddingId) return;

    try {
      const weddingDoc = await getDoc(doc(db, "weddings", weddingId));
      const data = weddingDoc.data();
      let nextWedding = {
        id: weddingDoc.id,
        ...data,
      }

      // Debug to allow the wedding website to be manually set to localhost
      if (process.env.REACT_APP_DEBUG_WEBSITE_URL_OVERRIDE) {
        nextWedding.websiteUrl = process.env.REACT_APP_DEBUG_WEBSITE_URL_OVERRIDE
      }

      setWedding(nextWedding);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching the wedding");
    }
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    fetchUserData();
  }, [user, loading]);

  const handleUpdateWedding = async (key, value) => {
    if (!wedding?.id) return;

    await updateDoc(doc(db, "weddings", wedding.id), {
      [key]: value,
    });

    setWedding({
      ...wedding,
      [key]: value,
    });
  }


  return <Component {...props} auth={auth} user={user} wedding={wedding} updateWedding={handleUpdateWedding} />
};
