import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./containers/Home";
import Guests from "./containers/Guests";
import Website from "./containers/Website";
import Login from "./containers/Login";
import Wedding from "./containers/Wedding";
import Forms from "./containers/Forms";
import MyAccount from "./containers/MyAccount";
import Main from "./layouts/Main";
import Unauthenticated from "./layouts/Unauthenticated";

const ROUTE_TITLES = {
  "/guests": "Guests",
  "/website": "Website",
  "/forms": "Forms",
}

const getRouteTitle = (location) => {
  return ROUTE_TITLES[location.pathname];
}

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Main getRouteTitle={getRouteTitle} />}>
        {/* <Route path="wedding" element={<Wedding />} /> */}
        <Route path="guests" element={<Guests />} />
        <Route path="website" element={<Website />} />
        {/* <Route path="forms" element={<Forms />} /> */}
        <Route path="settings/my-account" element={<MyAccount />} />
      </Route>
      <Route path="/login" element={<Unauthenticated><Login /></Unauthenticated>} />
    </Routes>
  );
}
