import React from 'react';
import AppRouter from './Router';


function App() {
  return (
    // <Main>
      <AppRouter />
    // </Main>
  );
}

export default App;
