import React from 'react';
import EditContentPage from './EditContentPage';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Alert, Heading, TextInput, Button, Checkbox, HorizontalRule } from "../widgets";
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { useSnackbar } from 'notistack';

export default function EditPage({ wedding, page, setPage, fetchPages, updatePage, forms, pages }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handlePageSave = async () => {
    await updatePage(page.docId, page.title, page.published, page.data);
    await fetchPages();

    enqueueSnackbar('Successfully saved!', {
      variant: 'success',
    });
  }

  if (!page) return;

  return (
    <>
      {page.allowDelete
        ? <>
          <Heading level={1}>
            Page Information
          </Heading>
          <Stack spacing={2} direction="column">
            <TextInput
              label="Placeholder"
              value={page.title}
              onChange={(e) => setPage({ ...page, title: e.target.value })}
              required
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={page.published}
                  onChange={(e) => setPage({ ...page, published: e.target.checked })}
                />
              }
              label="Published" />
          </Stack>

          <Stack sx={{ py: 2 }} spacing={2} direction="row">
            <Button
              onClick={handlePageSave}
              icon={faFloppyDisk}
            >
              Save
            </Button>
          </Stack>
        </>
        : <>
          <Heading level={1}>
            Page Information
          </Heading>
          <Alert severity="info">Page information is not editable for the home page.</Alert>
        </>}
      <HorizontalRule />
      {page.type === "CONTENT"
        ? <EditContentPage
            wedding={wedding}
            page={page}
            fetchPages={fetchPages}
            updatePage={updatePage}
            setPage={setPage}
            forms={forms}
            pages={pages}
          />
        : null}
    </>
  )
}
