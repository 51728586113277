import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import { Button, TabSelectorList, TabSelector, TabContentList, TabContent, TabSelectorActionList } from "../widgets";
// import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import AddPageDialog from "./AddPageDialog";
import EditPage from './EditPage';
import { useSnackbar } from 'notistack';
import { faFileCirclePlus, faEye } from '@fortawesome/free-solid-svg-icons'

const DEFAULT_NEW_PAGE = { title: "", type: "CONTENT", data: [] };

export default function PagesTabs({ wedding, pages, fetchPages, addPage, updatePage, websiteUrl, forms }) {
  const [activeTab, setActiveTab] = useState(undefined);

  const [isAddPageDialogOpen, setIsAddPageDialogOpen] = useState(false);
  const [newPage, setNewPage] = useState(DEFAULT_NEW_PAGE);
  const [editPageDocId, setEditPageDocId] = useState();
  const [editPage, setEditPage] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (pages && activeTab === undefined) {
      handleTabChange(pages[0].docId);
    }
  }, [pages]);

  const handleTabChange = (tabKey) => {
    const nextEditPage = pages.find((page) => page.docId === tabKey);
    setEditPageDocId(tabKey);
    setEditPage(nextEditPage);
    setActiveTab(tabKey);
  }

  const handleAddPageDialogOpen = () => {
    setIsAddPageDialogOpen(true);
  }

  const handleAddPageDialogClose = async () => {
    await fetchPages();
    setNewPage(DEFAULT_NEW_PAGE);
    setIsAddPageDialogOpen(false);
  }

  const handleAddPage = async () => {
    await addPage(newPage.title, newPage.type);
    handleAddPageDialogClose();

    enqueueSnackbar('Successfully added page!', {
      variant: 'success',
    });
  }

  return (
    <>
      <TabSelectorList onChange={handleTabChange} selectedTabKey={activeTab}>
        {pages?.map((page) => {
          return (
            <TabSelector tabKey={page.docId}>{page.title}</TabSelector>
          )
        })}
        <TabSelectorActionList>
          <Button
            onClick={handleAddPageDialogOpen}
            icon={faFileCirclePlus}
          >
            Add Page
          </Button>
          <Button
            onClick={() => window.open(websiteUrl, '_blank').focus()}
            icon={faEye}
          >
            Preview Site
          </Button>
        </TabSelectorActionList>
      </TabSelectorList>

      <TabContentList selectedTabKey={activeTab}>
        {pages?.map((page) => {
          return (
            <TabContent tabKey={page.docId}>
              <EditPage
                wedding={wedding}
                page={editPage}
                setPage={setEditPage}
                fetchPages={fetchPages}
                updatePage={updatePage}
                forms={forms}
                pages={pages}
              />
            </TabContent>
          )
        })}
      </TabContentList>
      <AddPageDialog
        open={isAddPageDialogOpen}
        onClose={handleAddPageDialogClose}
        page={newPage}
        setPage={setNewPage}
        onSave={handleAddPage}
      />
    </>
  );
}
