import * as React from "react";
import styles from "./StatsList.module.css";

export const StatsList = ({children}) => {
  return (
    <div className={styles.StatsList}>
      {children}
    </div>
  )
}

export const Stat = ({title, value, description}) => {
  return (
    <div className={styles.Stat}>
      <div className={styles.Title}>{title}</div>
      <div className={styles.Value}>{value}</div>
      <div className={styles.Description}>{description}</div>
    </div>
  )
}
