import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from "./Button.module.css";

export const Button = ({onClick, disabled, icon, children, format, variant}) => {
  let buttonClassName = [styles.Base];
  if (format === "link") {
    buttonClassName.push(styles.Link);
  } else {
    buttonClassName.push(styles.Button);
  }

  if (variant === "danger") buttonClassName.push(styles.DangerStyle);

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={buttonClassName.join(" ")}
      >
      {icon
        ? <span className={styles.Icon}><FontAwesomeIcon icon={icon} /></span>
        : null}
      {children}
    </button>
  );
}
