import * as React from 'react';
import { withAuthentication } from "./AuthenticatedPage";

function WeddingSettings() {
  return (
    <>
      <h1>Wedding</h1>
      <h2>Nothing to see here... yet!</h2>
    </>
  );
}

export default withAuthentication(WeddingSettings);
