import React, { useMemo } from "react";
import { StatsList, Stat } from "../components/widgets";

// Map of aggregator types to user friendly names
const STATS_QUESTION_AGGREGATOR_TO_TITLE = {
  'TOTAL': 'Total Responses',
  'YES_RESPONSES': 'Yes Responses',
  'NO_RESPONSES': 'No Responses',
  'PCT_OF_HOUSEHOLDS': '% of Households',
}

// For each question type, what are the allowed aggregators?
const STATS_AGGREGATORS_FOR_QUESTION_TYPE = {
  "YES_NO": ["TOTAL", "YES_RESPONSES", "NO_RESPONSES", "PCT_OF_HOUSEHOLDS"],
  "YES_NO_QUALIFIED": ["TOTAL"],
  "FREE_TEXT": ["TOTAL"],
}

const getQuestionKey = (questionId, aggregator) => {
  // Each possible stat will have a unique combination of questionId and aggregator.
  // As such, we build a unique key so for various operations so we can pop objects into a map
  // for fast retrieval rather than having to do nested loops.
  return `${questionId}::${aggregator}`;
}

const getStatsOptions = (formQuestions) => {
  // Enumerate all the possible options for different stats we might have.
  // Return these as a map of questionKey to stat information.
  const statsOptions = {};
  formQuestions?.forEach((question) => {
    STATS_AGGREGATORS_FOR_QUESTION_TYPE[question.type].forEach(aggregator => {
      const questionKey = getQuestionKey(question.questionId, aggregator);
      statsOptions[questionKey] = {
        questionId: question.questionId,
        title: question.label,
        description: STATS_QUESTION_AGGREGATOR_TO_TITLE[aggregator],
        aggregator: aggregator,
      };
    });
  });

  return statsOptions;
}

const getStatsConfigByQuestionKey = (statsConfig) => {
  // Get the persisted stats configuration (i.e. which are visible on screen) and organise them
  // into a map of questionKey to stat config.
  const configByQuestion = {};
  statsConfig.forEach((config) => {
    const questionKey = getQuestionKey(config.questionId, config.aggregator);
    configByQuestion[questionKey] = config;
  });

  return configByQuestion;
}

const getFormQuestionsByQuestion = (formQuestions) => {
  // Organise all the formQuestions into a map of questionId to question.
  const formQuestionsByQuestion = {};
  formQuestions.forEach((question) => {
    formQuestionsByQuestion[question.questionId] = question;
  });

  return formQuestionsByQuestion;
}

const getStats = (households, statsOptions, formQuestionsByQuestion, configByQuestion) => {
  // This function is the actual stat calculation itself and returns an array of all stats ready
  // to be filtered (i.e. only display if visible) and displayed.
  const statsByQuestionKey = {};

  // Clone all possible options into a new map and add in whether or not they are currently visible
  // based on the configuration
  for (const [key, value] of Object.entries(statsOptions)) {
    statsByQuestionKey[key] = {
      title: value.title,
      description: value.description,
      value: 0,
      aggregator: value.aggregator,
      visible: !!configByQuestion[key],  // If it exists in the config then it's visible
    };
  }

  // Iterate through each household...
  households?.forEach(household => {
    // ...and then that households form responses...
    household?.formResponses?.forEach(formResponse => {
      // ...find the corresponding original question...
      const question = formQuestionsByQuestion[formResponse.questionId];
      // ...given the question type, loop through the available aggregators for it...
      STATS_AGGREGATORS_FOR_QUESTION_TYPE[question.type].forEach(aggregator => {
        const questionKey = getQuestionKey(question.questionId, aggregator);
        const guestAnswers = Object.values(formResponse.guestAnswers || {});
        // Handle the guest cardinality where we want to count `guestAnswers`.
        if (question.cardinality === "GUEST") {
          if (aggregator === "TOTAL") {
            // If it's a total then we just count all the guest answers given and add them on
            statsByQuestionKey[questionKey].value += guestAnswers.length;
          }

          if (aggregator === "YES_RESPONSES") {
            // For Yes responses count all true guest answers only and add them on
            statsByQuestionKey[questionKey].value += guestAnswers.filter(answer => answer === true).length;
          }

          if (aggregator === "NO_RESPONSES") {
            // For No responses count all false guest answers only and add them on
            statsByQuestionKey[questionKey].value += guestAnswers.filter(answer => answer === false).length;
          }

          if (aggregator === "PCT_OF_HOUSEHOLDS") {
            // For % of households we just want to count if we have any guest answers at all and
            // increment the value. We will convert this to a percentage later.
            statsByQuestionKey[questionKey].value += guestAnswers.length > 0 ? 1 : 0;
          }
        // Handle the household cardinality where we want to count `answers`.
        } else if (question.cardinality === "HOUSEHOLD") {
          if (aggregator === "TOTAL") {
            // If it's a total then we just want to see if we have any answer at all
            statsByQuestionKey[questionKey].value += !!formResponse.answer ? 1 : 0;
          }

          if (aggregator === "YES_RESPONSES") {
            // For Yes responses count we want to check if the answer provided is explicitly true
            // and if it is, add one.
            statsByQuestionKey[questionKey].value += formResponse.answer === true ? 1 : 0;
          }

          if (aggregator === "NO_RESPONSES") {
            // For No responses count we want to check if the answer provided is explicitly false
            // and if it is, add one.
            statsByQuestionKey[questionKey].value += formResponse.answer === false ? 1 : 0;
          }

          if (aggregator === "PCT_OF_HOUSEHOLDS") {
            // For % of households we just want to count if we have any guest answers at all and
            // increment the value. We will convert this to a percentage later.
            statsByQuestionKey[questionKey].value += !!formResponse.answer ? 1 : 0;
          }
        }
      });
    });
  });


  // Do a final conversion of the stats from a map to a simple array and calculate any required
  // percentages.
  const numHouseholds = households.length;
  const stats = [];
  Object.values(statsByQuestionKey).forEach(stat => {
    if (stat.aggregator === "PCT_OF_HOUSEHOLDS") {
      stat.value = Math.round((stat.value / numHouseholds) * 100) + "%";
    }
    stats.push(stat);
  })

  return stats;
}

const GuestStats = ({households, statsConfig, formQuestions}) => {
  const configByQuestion = useMemo(() => getStatsConfigByQuestionKey(statsConfig), [statsConfig]);
  const formQuestionsByQuestion = useMemo(() => getFormQuestionsByQuestion(formQuestions), [formQuestions]);
  const statsOptions = useMemo(() => getStatsOptions(formQuestions), [formQuestions]);
  const stats = useMemo(() => getStats(households, statsOptions, formQuestionsByQuestion, configByQuestion), [households, statsOptions, formQuestionsByQuestion, configByQuestion]);

  return (
    <StatsList>
      {/* Hard code the total guests count - this can't be configured and always appears first. */}
      <Stat
        title="Guests"
        description="Total Invited"
        value={households?.reduce((count, current) => count + current.guests.length, 0)}
        />
      {stats.filter(stat => stat.visible).map(stat => {
        return (
          <Stat
            title={stat.title}
            description={stat.description}
            value={stat.value}
            />
        )
      })}
    </StatsList>
  )
}

export default GuestStats;
