import React from "react";
import { Table, TableHead, TableBody, TableRow, TableCell } from "../widgets";

const GuestListRecentActivity = ({recentActivity}) => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Household
            </TableCell>
            <TableCell>
              Action
            </TableCell>
            <TableCell>
              Date/Time
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recentActivity.map((activity) => {
            return (
              <TableRow>
                <TableCell>{activity.household}</TableCell>
                <TableCell>{activity.description}</TableCell>
                <TableCell>{activity.dateTime ? `${activity.dateTime.toLocaleDateString()} ${activity.dateTime.toLocaleTimeString()}`: null}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

export default GuestListRecentActivity;
