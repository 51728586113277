import React from "react";
import styles from "./DropDown.module.css";

export const DropDown = ({value, options, onChange, label}) => {
  return (
    <div className={styles.DropDownContainer}>
      {label
        ? <label className={styles.Label}>{label}</label>
        : null}
      <select
        className={styles.DropDown}
        value={value}
        onChange={onChange}
      >
        {options?.map((option) => <option value={option.value}>{option.label}</option>)}
      </select>
    </div>
  )
}
