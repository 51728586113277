import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import DoneIcon from '@mui/icons-material/Done';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import styles from './ContactHistoryDialog.module.css';

function djb2(str) {
  var hash = 5381;
  for (var i = 0; i < str.length; i++) {
    hash = ((hash << 5) + hash) + str.charCodeAt(i); /* hash * 33 + c */
  }
  return hash;
}

function getStringColor(str) {
  var hash = djb2(str);
  var r = (hash & 0xFF0000) >> 16;
  var g = (hash & 0x00FF00) >> 8;
  var b = hash & 0x0000FF;
  return "#" + ("0" + r.toString(16)).substr(-2) + ("0" + g.toString(16)).substr(-2) + ("0" + b.toString(16)).substr(-2);
}

export default function ContactHistoryDialog({ open, onClose, household, mail, messages, onErrorAcknowledge }) {
  const guests = {};
  household?.guests?.forEach((guest) => {
    guests[guest.id] = {
      name: `${guest.firstName} ${guest.lastName}`,
      initials: `${guest.firstName} ${guest.lastName}`.split(' ').map((word) => word.charAt()).join('').toUpperCase(),
      color: getStringColor(`${guest.firstName} ${guest.lastName}`),
    }
  })
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Correspondence with {household?.guests.map((guest) => `${guest.firstName} ${guest.lastName}`).join(", ")}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <Stack spacing={2} direction="column">
              <h1>Emails</h1>
              {mail?.sort((a,b) => (a.delivery?.startTime < b.delivery?.startTime) ? 1 : ((b.delivery?.startTime < a.delivery?.startTime) ? -1 : 0)).map((thisMail) => {
                return (
                  <div className={styles.Message}>
                    <div className={styles.Avatar} style={{ backgroundColor: guests[thisMail.guestId].color }}>
                      {guests[thisMail.guestId].initials}
                    </div>
                    <div className={styles.MessageDetails}>
                      {thisMail.delivery?.state === "SUCCESS"
                        ? <div className={styles.SentDetails}>
                            <div className={styles.SentIcon}>
                              <DoneIcon sx={{color: "green" }} />
                            </div>
                          </div>
                        : null}
                      {mail.delivery?.error && !mail.errorAcknowledged
                        ? <div className={styles.ErrorDetails}>
                            <div className={styles.ErrorIcon}>
                              <AnnouncementIcon sx={{cursor: 'pointer', color: "red" }} onClick={() => onErrorAcknowledge("email", mail.docId)} />
                            </div>
                            <div className={styles.ErrorMessage}>
                              {mail.docId} - {mail.delivery.error}
                            </div>
                          </div>
                        : null}
                      <div className={styles.Recipient}>{guests[thisMail.guestId].name}: {thisMail.to}</div>
                      <div className={styles.Timestamp}>{"" + new Date(thisMail.delivery.endTime.seconds * 1000)}</div>
                      <div className={styles.EmailSubject}>{thisMail.message.subject}</div>
                      <div className={styles.EmailBody} dangerouslySetInnerHTML={{ __html: thisMail.message.html }} />
                    </div>
                  </div>
                )
              })}
              <h1>SMS</h1>
              {messages?.sort((a,b) => (a.delivery?.startTime < b.delivery?.startTime) ? 1 : ((b.delivery?.startTime < a.delivery?.startTime) ? -1 : 0)).map((message) => {
                return (
                  <div className={styles.Message}>
                    <div className={styles.Avatar} style={{ backgroundColor: guests[message.guestId].color }}>
                      {guests[message.guestId].initials}
                    </div>
                    <div className={styles.MessageDetails}>
                      {message.delivery?.state === "SUCCESS"
                        ? <div className={styles.SentDetails}>
                            <div className={styles.SentIcon}>
                              <DoneIcon sx={{color: "green" }} />
                            </div>
                          </div>
                        : null}
                      {message.delivery?.errorCode
                        ? <div className={styles.ErrorDetails}>
                            <div className={styles.ErrorIcon}>
                              <AnnouncementIcon sx={{cursor: 'pointer', color: message.errorAcknowledged ? "orange" : "red" }} onClick={() => onErrorAcknowledge("sms", message.docId)} />
                            </div>
                            <div className={styles.ErrorMessage}>
                              {message.errorAcknowledged
                              ? <p className={styles.ErrorHasBeenAcknowledged}>This error has been acknowledged</p>
                              : null}
                              {message.delivery.errorCode}: {message.delivery.errorMessage}
                            </div>
                          </div>
                        : null}
                      <div className={styles.Recipient}>{guests[message.guestId].name}: {message.to}</div>
                      <div className={styles.Timestamp}>{"" + new Date(message.delivery.endTime.seconds * 1000)}</div>
                      <div className={styles.SMSBody}>{message.body}</div>
                    </div>
                  </div>
                )
              })}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
