import React, { useState, useEffect } from "react";
import styles from "./Loading.module.css";

export const Loading = ({message}) => {
  const [isVisible, setIsVisible] = useState(false);

  // Only show the throbber if we've been waiting for 1 second, it's a bit jarring to flash it up
  // quickly for a < 1s load time.
  useEffect(() => {
    setTimeout(() => setIsVisible(true), 1000);
  }, []);

  return (
    <div className={styles.ThrobberContainer}>
      {isVisible
        ? <div className={styles.Throbber}>
            <div className={styles.Half1}></div>
            <div className={styles.Half2}></div>
            <div className={styles.Half3}></div>
            <div className={styles.Half4}></div>
          </div>
        : null}
    </div>
  )
}
