import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import TextField from "@mui/material/TextField";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/");
  }, [user, loading, navigate]);

  return (
    <>
      <h1>Welcome! Sign in to Weddings</h1>
      <form>
        <Stack spacing={2} direction="column">
          <TextField
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            autocomplete
            variant="outlined"
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autocomplete
            variant="outlined"
          />

          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              onClick={() => logInWithEmailAndPassword(email, password)}
            >
              Login
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  );
}
