import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import styles from "./Modal.module.css";

export const Modal = ({icon, title, description, isVisible, onClose, children}) => {

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isVisible]);

  if (isVisible) {
    let modalActions;
    React.Children.forEach(children, child => {
      if (React.isValidElement(child) && child.type.name === ModalActionList.name) {
        modalActions = child;
      }
    });

    let fixedHeaderContent;
    React.Children.forEach(children, child => {
      if (React.isValidElement(child) && child.type.name === ModalFixedHeaderContent.name) {
        fixedHeaderContent = child;
      }
    });

    let childrenWithoutActions = [];
    React.Children.forEach(children, child => {
      if (React.isValidElement(child) && child.type.name !== ModalActionList.name && child.type.name !== ModalFixedHeaderContent.name) {
        childrenWithoutActions.push(child);
      }
    });

    return (
      <div>
        <div className={styles.Overlay}>
          <div className={styles.Modal}>
            <div className={styles.Header}>
              <div className={styles.Icon}>
                <FontAwesomeIcon icon={icon} />
              </div>
              <div className={styles.TitleDescriptionContainer}>
                <div className={styles.Title}>
                  {title}
                </div>
                <div className={styles.Description}>
                  {description}
                </div>
              </div>
              {onClose
                ? <div className={styles.CloseIcon} role="button" onClick={onClose}>
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                : null}
            </div>
            {fixedHeaderContent}
            {children
              ? <div className={styles.Content}>
                  {childrenWithoutActions}
                </div>
              : null}
            {modalActions}
          </div>
          <div className={styles.BottomSpacer}></div>
        </div>
      </div>
    );
  }
}

export const ModalFixedHeaderContent = ({children}) => {
  return (
    <div className={styles.ModalFixedHeaderContent}>
      {children}
    </div>
  );
}

export const ModalActionList = ({children}) => {
  return (
    <div className={styles.ModalActionList}>
      {children}
    </div>
  );
}
