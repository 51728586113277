import React, { useState, useEffect } from "react";
import { withAuthentication } from "./AuthenticatedPage";
import Pages from "../components/website/Pages";
import { db } from "../firebase";
import { query, collection, getDocs, addDoc, updateDoc, doc } from "firebase/firestore";
import { Loading } from "../components/widgets";
import { slugify } from '../utils';

function Website({ wedding }) {
  const [pages, setPages] = useState();
  const [forms, setForms] = useState();


  const addPage = async (title, type) => {
    await addDoc(collection(db, "weddings", wedding?.id, "website-pages"), {
      title,
      slug: slugify(title),
      type,
      allowDelete: true,
      published: false
    });
  }

  const updatePage = async (docId, title, published, data) => {
    const page = pages.find((page) => page.docId === docId);

    const nextPage = {
      ...page,
      title,
      slug: page.allowDelete ? slugify(title) : page.slug,
      data,
      published
    };

    await updateDoc(doc(db, "weddings", wedding?.id, "website-pages", docId), nextPage);

    const nextPages = [];
    pages.forEach((page) => {
      if (page.docId === docId) {
        nextPages.push(nextPage);
      } else {
        nextPages.push({
          ...page
        })
      }
    });
    setPages(nextPages);
  }

  const fetchPages = async () => {
    if (!wedding) return;

    try {
      const q = query(collection(db, "weddings", wedding?.id, "website-pages"));
      const doc = await getDocs(q);

      let nextPages = [];
      doc.docs.forEach(async (doc) => {
        nextPages.push({
          docId: doc.id,
          ...doc.data(),
        });
      });

      setPages(nextPages);
    } catch (err) {
      console.error(err);
      alert("An error occurred while fetching pages");
    }
  };

  const fetchForms = async () => {
    if (!wedding) return;

    try {
      const q = query(collection(db, "weddings", wedding?.id, "forms"));
      const doc = await getDocs(q);

      let nextForms = [];
      doc.docs.forEach(async (doc) => {
        nextForms.push({
          docId: doc.id,
          ...doc.data(),
        });
      });

      setForms(nextForms);
    } catch (err) {
      console.error(err);
      alert("An error occurred while fetching forms");
    }
  }

  useEffect(() => {
    fetchPages();
    fetchForms();
  }, [wedding]);

  return (
    <>
      {pages
        ? <Pages
          wedding={wedding}
          pages={pages}
          fetchPages={fetchPages}
          addPage={addPage}
          updatePage={updatePage}
          websiteUrl={wedding?.websiteUrl}
          forms={forms}
        />
        : <div style={{flex: 1}}>
            <Loading />
          </div>}
    </>
  );
}

export default withAuthentication(Website);
