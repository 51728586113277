import React from "react";
import { TruncatedText } from '../widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import styles from './FormResponseAnswer.module.css';

const QuestionGuestYesNo = ({ guests }) => {
  return (
    <div className={styles.FormResponse}>
      <ol>
        {guests.map((guest) => {
          return (
            <li>
              <span style={{ display: "inline-block", width: "24px", color: "#8c8c8c" }}><FontAwesomeIcon icon={faCheck} size="lg" /></span>
            </li>
          );
        })}
      </ol>
    </div>
  )
}

const QuestionHouseholdFreeText = ({ answer, format }) => {
  return (
    <div>
      {answer
        ? <TruncatedText width={format === "table" ? "100px" : undefined} maxChars={format === "table" ? 50 : undefined}>
            {answer}
          </TruncatedText>
        : null}
    </div>
  )
}

const QuestionGuestFreeText = ({ guests, guestAnswers, format }) => {
  return (
    <div className={styles.FormResponse}>
      <ol>
        {guests.map((guest) => {
          return (
            <>
            {guestAnswers[guest.id]
              ? <li>
                  {guest.firstName} {guest.lastName}:
                  <TruncatedText width={format === "table" ? "100px" : undefined} maxChars={format === "table" ? 50 : undefined}>
                    {guestAnswers[guest.id]}
                  </TruncatedText>
                </li>
              : null}
            </>
          );
        })}
      </ol>
    </div>
  )
}

const FormResponseAnswer = ({ type, cardinality, guests, answer, guestAnswers, format }) => {
  let AnswerComponent;
  switch (true) {
    case (type === "YES_NO" && cardinality === "GUEST"):
      AnswerComponent = QuestionGuestYesNo;
      break;
    case (type === "YES_NO_QUALIFIED" && cardinality === "GUEST"):
      AnswerComponent = QuestionGuestFreeText;
      break;
    case (type === "FREE_TEXT" && cardinality === "HOUSEHOLD"):
      AnswerComponent = QuestionHouseholdFreeText;
      break;
    case (type === "FREE_TEXT" && cardinality === "GUEST"):
      AnswerComponent = QuestionGuestFreeText;
      break;
    default:
      return;
  }

  return (
    <AnswerComponent
      guests={guests}
      answer={answer}
      guestAnswers={guestAnswers}
      format={format}
    />
  );
}

export default FormResponseAnswer;
