import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SelectFile = ({ setWizardStep, setGuestList, guestList, setColumnList }) => {
  function handleFile(file) {
    const reader = new FileReader();
    const nextGuestList = [];
    reader.onload = function (e) {
      const rows = e.target.result.split("\n");

      rows.forEach((row, idx) => {
        const columns = row.split(/(?!\B"[^"]*),(?![^"]*"\B)/);
        if (idx === 0) {
          setColumnList(columns);
        } else {
          nextGuestList.push(columns);
        }
      });

      setGuestList(nextGuestList);
    };
    reader.readAsText(file);
  }

  return (
    <>
      <DialogContent>
        <h1>Select File</h1>
        <input type="file" accept="text/csv" onChange={(e) => { handleFile(e.target.files[0]) }} />
        {guestList
          ? <Box sx={{ p: 2 }}>Guests to Import: {guestList.length}</Box>
          : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setWizardStep("FIELD_MAPPER")}>
          Next
        </Button>
      </DialogActions>
    </>
  )
}

const FieldMapper = ({ setWizardStep, guestList, columnList, columnMap, setColumnMap }) => {
  const ColumnSelector = ({ name }) => {
    return (
      <FormControl fullWidth>
        <Select
          value={columnMap[name]}
          onChange={(e) => {
            setColumnMap({
              ...columnMap,
              [name]: e.target.value,
            })
          }}
        >
          {columnList.map((column, idx) => <MenuItem value={idx}>{column}</MenuItem>)}
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <DialogContent>
        <p>For each field select the corresponding column in the CSV you have uploaded.</p>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell align="right">Column Name in CSV</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  First Name
                </TableCell>
                <TableCell>
                  <ColumnSelector name="firstName" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Last Name
                </TableCell>
                <TableCell>
                  <ColumnSelector name="lastName" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Household
                </TableCell>
                <TableCell>
                  <ColumnSelector name="household" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Phone
                </TableCell>
                <TableCell>
                  <ColumnSelector name="phone" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  <ColumnSelector name="email" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setWizardStep("SELECT_FILE")}>
          Back
        </Button>
        <Button onClick={() => setWizardStep("PREVIEW")}>
          Next
        </Button>
      </DialogActions>
    </>
  )
}

const Preview = ({ setWizardStep, columnMap, guestList, households, setHouseholds }) => {
  const guestsToHouseholds = () => {
    const households = {};
    guestList.forEach((guest) => {
      const firstName = guest[columnMap["firstName"]].trim().replace(/(^"|"$)/g, '');
      const lastName = guest[columnMap["lastName"]].trim().replace(/(^"|"$)/g, '');
      const household = guest[columnMap["household"]].trim().replace(/(^"|"$)/g, '');
      const phone = guest[columnMap["phone"]].trim().replace(/(^"|"$)/g, '');
      const email = guest[columnMap["email"]].trim().replace(/(^"|"$)/g, '');

      if (households[household]) {
        households[household].guests.push({
          firstName,
          lastName,
          phone,
          email,
        })
      } else {
        households[household] = {
          name: household,
          guests: [{
            firstName,
            lastName,
            phone,
            email,
          }]
        };
      }
    });

    setHouseholds(Object.values(households));
  }

  useEffect(() => {
    guestsToHouseholds();
  }, [columnMap, guestList]);

  if (!households) {
    return <p>Generating preview...</p>
  }

  return (
    <>
      <DialogContent>
        <p>Please check the preview before importing.</p>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Household</TableCell>
                <TableCell>Party Of</TableCell>
                <TableCell>Guests</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {households.map((household) => {
                // const household = households[householdKey];
                return (
                  <TableRow
                    // key={guest.docId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{household.name}</TableCell>
                    <TableCell>
                      {household.guests.length}
                    </TableCell>
                    <TableCell>
                      {household.guests.map((guest) => <div>{`${guest.firstName} ${guest.lastName}`}</div>)}
                    </TableCell>
                    <TableCell>
                      {household.guests.map((guest) => <div>{guest.phone}</div>)}
                    </TableCell>
                    <TableCell>
                      {household.guests.map((guest) => <div>{guest.email}</div>)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setWizardStep("FIELD_MAPPER")}>
          Back
        </Button>
        <Button onClick={() => setWizardStep("COMPLETE_IMPORT")}>
          Next
        </Button>
      </DialogActions>
    </>
  )
}

const CompleteImport = ({ setWizardStep, households, addHousehold, onClose }) => {
  const [status, setStatus] = useState("NOT_STARTED");
  const [progressCounter, setProgressCounter] = useState(1);

  const importGuests = async () => {
    setStatus("IMPORTING");
    households.forEach(async (household, idx) => {
      await addHousehold(household.guests);
      setProgressCounter(progressCounter + 1);
      if (idx === households.length - 1) {
        setStatus("COMPLETE");
      }
    });

  }

  return (
    <>
      <DialogContent>
        <Box>
          {households?.reduce((count, current) => count + current.guests.length, 0)} Guests to import
        </Box>
        {status === "NOT_STARTED"
          ? <Button onClick={importGuests}>
            Begin Import
          </Button>
          : null}
        {status === "IMPORTING"
          ? <Box>Importing {progressCounter} of {households.length} households...</Box>
          : null}
        {status === "COMPLETE"
          ? <Box>Success! All guests have been imported</Box>
          : null}
      </DialogContent>
      <DialogActions>
        {status === "NOT_STARTED"
          ? <Button onClick={() => setWizardStep("PREVIEW")}>
            Back
          </Button>
          : null}
        {status === "COMPLETE"
          ? <Button onClick={onClose}>
            Finish
          </Button>
          : null}
      </DialogActions>
    </>
  )
}

export default function ImportGuestListDialog({ open, onClose, addHousehold }) {
  const [wizardStep, setWizardStep] = useState("SELECT_FILE");
  const [guestList, setGuestList] = useState();
  const [columnList, setColumnList] = useState();
  const [columnMap, setColumnMap] = useState({});
  const [households, setHouseholds] = useState();

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Import Guest List
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {wizardStep === "SELECT_FILE"
        ? <SelectFile setWizardStep={setWizardStep} setGuestList={setGuestList} guestList={guestList} setColumnList={setColumnList} />
        : null}
      {wizardStep === "FIELD_MAPPER"
        ? <FieldMapper setWizardStep={setWizardStep} columnMap={columnMap} setColumnMap={setColumnMap} columnList={columnList} />
        : null}
      {wizardStep === "PREVIEW"
        ? <Preview setWizardStep={setWizardStep} columnMap={columnMap} guestList={guestList} households={households} setHouseholds={setHouseholds} />
        : null}
      {wizardStep === "COMPLETE_IMPORT"
        ? <CompleteImport setWizardStep={setWizardStep} households={households} addHousehold={addHousehold} onClose={onClose} />
        : null}
    </Dialog>
  );
}
