import React, { useState, useEffect     } from "react";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import styles from "./HTMLEditor.module.css";

const config = {
  options: ['inline', 'blockType', 'fontSize', 'list', 'link', 'emoji', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H3', 'H4'],
  },
}

export default config;


export const HTMLEditor = ({ html, onChange, label }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (html) {
      const blocksFromHtml = htmlToDraft(html);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const loadedEditorState = EditorState.createWithContent(contentState);
      setEditorState(loadedEditorState);
    }
  }, []);

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);

    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onChange(html);
  };

  return (
    <div className={styles.Editor}>
      {label
        ? <label className={styles.Label}>{label}</label>
        : null}
      <div className={styles.EditorContainer}>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorStateChange}
          toolbar={config}
          toolbarClassName={styles.Toolbar}
          editorClassName={styles.EditorTextArea}
          />
      </div>
    </div>
  );
}
