import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TextField from "@mui/material/TextField";
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function AddPageDialog({ open, onClose, page, setPage, onSave }) {
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Add Page
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ py: 2 }} spacing={2} direction="column">
            <TextField
              value={page?.title}
              label="Title"
              required
              variant="outlined"
              onChange={(e) => setPage({ ...page, title: e.target.value })}
            />
            <FormControl fullWidth>
              <InputLabel id="add-new-page-type-label">Page Type</InputLabel>
              <Select
                labelId="add-new-page-type-label"
                id="add-new-page-type"
                label="Page Type"
                value={page?.type}
                onChange={(e) => setPage({ ...page, type: e.target.value })}
              >
                <MenuItem value="CONTENT">Content</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSave}>
            Add Page
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
