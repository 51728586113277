import React, { useState } from "react";
import FormResponseAnswer from './FormResponseAnswer';
import { useSnackbar } from 'notistack';
import { uuid4 } from "../../utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faPlusCircle, faTrash, faUser, faUserPlus, faCopy, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { RSVPBadge, Heading, Grid, TextInput, Button, Modal, TabSelectorList, TabSelector, TabContentList, TabContent, ModalActionList, ModalFixedHeaderContent, ModalConfirm } from "../widgets";
import styles from './HouseholdDialog.module.css';

export default function HouseholdDialog({ open, onClose, mode, household, setHousehold, onDelete, onSave, websiteUrl, questions }) {
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("GUESTS");
  const { enqueueSnackbar } = useSnackbar();

  const handleOnGuestChange = (guestIdx, fieldName, value) => {
    const nextGuests = [];
    household.guests.forEach((guest, idx) => {
      if (guestIdx === idx) {
        nextGuests.push({
          ...guest,
          [fieldName]: value,
        });
      } else {
        nextGuests.push(guest);
      }
    });
    setHousehold({
      ...household,
      guests: nextGuests,
    });
  }

  const handleAddGuest = () => {
    const nextHousehold = {
      ...household,
      guests: household.guests,
    }
    nextHousehold.guests.push({
      id: uuid4(),
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    });
    setHousehold(nextHousehold);
  }

  const handleDeleteGuest = (guestIdx) => {
    const nextGuests = [];
    household.guests.forEach((guest, idx) => {
      if (guestIdx === idx) {
        return;
      } else {
        nextGuests.push(guest);
      }
    });
    setHousehold({
      ...household,
      guests: nextGuests,
    });
  }

  const handleSaveHousehold = async () => {
    await onSave(household.docId, household.guests);
    onClose();
  }

  const handleConfirmDelete = () => {
    setIsDeleteConfirmDialogOpen(true);
  }

  const handleDeleteHousehold = async () => {
    setIsDeleteConfirmDialogOpen(false);
    await onDelete();
    onClose();
  }

  // TODO: remove the hard coding of this questionId.
  const rsvpQuestion = questions.find(column => column.label === "RSVP?");
  const rsvpResponse = household?.formResponses?.find((response) => response.questionId === rsvpQuestion.questionId);

  return (
    <>
      <Modal
        isVisible={open}
        icon={mode === "NEW" ? faUserPlus : faUser}
        onClose={onClose}
        title={mode === "NEW" ? "New Household" : "Edit Household"}
        description="Modify the guests associated with this Household."
        >

        {mode === "EDIT"
          ? <ModalFixedHeaderContent>
              <TabSelectorList onChange={setActiveTab} selectedTabKey={activeTab}>
                <TabSelector tabKey="GUESTS">Guests</TabSelector>
                <TabSelector tabKey="FORM_RESPONSES">Form Responses</TabSelector>
                <TabSelector tabKey="WEBSITE">Website</TabSelector>
              </TabSelectorList>
            </ModalFixedHeaderContent>
          : null}

        <TabContentList selectedTabKey={activeTab}>
          <TabContent tabKey="GUESTS">
            {household?.guests?.map((guest, idx) => {
              return (
                <>
                  <div className={styles.GuestHeader}>
                    <Heading level={1}>Guest {idx+1}</Heading>
                    <Button
                      onClick={() => handleDeleteGuest(idx)}
                      variant="danger"
                      format="link"
                      icon={faTrash}
                      >
                      Delete Guest {idx+1}
                    </Button>
                  </div>
                  <Grid columns={2}>
                    <TextInput
                      value={guest.firstName}
                      label="First Name"
                      required
                      onChange={(e) => handleOnGuestChange(idx, "firstName", e.target.value)}
                    />
                    <TextInput
                      label="Last Name"
                      value={guest.lastName}
                      onChange={(e) => handleOnGuestChange(idx, "lastName", e.target.value)}
                    />
                    <TextInput
                      label="Email"
                      type="email"
                      value={guest.email}
                      onChange={(e) => handleOnGuestChange(idx, "email", e.target.value)}
                    />
                    <TextInput
                      label="Phone"
                      value={guest.phone}
                      onChange={(e) => handleOnGuestChange(idx, "phone", e.target.value)}
                    />
                  </Grid>
                </>
              );
            })}
            <Button
              onClick={handleAddGuest}
              format="link"
              icon={faPlusCircle}
              >
              Add Guest to this Household
            </Button>
          </TabContent>
          <TabContent tabKey="FORM_RESPONSES">
            {!household?.formResponses
              ? <span className={styles.NoResponseYet}>No response yet</span>
              : <ul className={styles.FormResponses}>
                  <li className={styles.FormResponse}>
                    <Heading level={2}>
                      {rsvpQuestion.question}
                    </Heading>
                    <table>
                      {household?.guests?.map((guest) => {
                        return (
                          <tr>
                            <td className={styles.RSVPGuestName}>{guest.firstName} {guest.lastName}</td>
                            <td>
                              <RSVPBadge
                                guestResponse={rsvpResponse?.guestAnswers?.[guest.id]}
                                />
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </li>

                {household?.formResponses?.map((response) => {
                  if (response.questionId === rsvpQuestion.questionId) return;
                  const question = questions.find((q) => q.questionId === response.questionId);
                  const isAnswered = (response.guestAnswers && Object.values(response.guestAnswers).some((item) => !!item)) || !!response.answer;

                  return (
                    <li className={styles.FormResponse}>
                      <Heading level={2}>
                        {question.question}
                      </Heading>
                      <div>
                        {isAnswered
                          ? <FormResponseAnswer
                              type={question.type}
                              cardinality={question.cardinality}
                              guests={household.guests}
                              answer={response.answer}
                              guestAnswers={response.guestAnswers}
                              icon={question.icon}
                              iconColor={question.iconColor}
                            />
                          : <span className={styles.NoResponse}>No response given</span>}
                      </div>
                    </li>
                  );
                })}
              </ul>}
          </TabContent>
          <TabContent tabKey="WEBSITE">
            <div className={styles.WebsiteURLContainer}>
              <label>Website URL</label>
              <div className={styles.WebsiteURL}>
                <a href={`${websiteUrl}${household?.docId}`} target="_blank">{`${websiteUrl}${household?.docId}`}</a>
                <span className={styles.WebsiteURLIcon}>
                  <FontAwesomeIcon icon={faUpRightFromSquare} />
                </span>
              </div>
            </div>
          </TabContent>
        </TabContentList>


        {activeTab === "GUESTS"
          ? <ModalActionList>
              {mode === "EDIT"
                ? <Button
                    icon={faTrash}
                    onClick={handleConfirmDelete}
                    variant="danger"
                    >
                  Delete Household
                </Button>
                : null}
              <Button
                icon={faFloppyDisk}
                onClick={handleSaveHousehold}
                >
                {mode === "NEW" ? "Create Household" : "Save Household"}
              </Button>
            </ModalActionList>
          : null}

        {activeTab === "WEBSITE"
          ? <ModalActionList>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(`${websiteUrl}rsvp/${household?.docId}`);
                  enqueueSnackbar('Copied to clipboard!', {
                    variant: 'info',
                  });
                }}
                icon={faCopy}>
                Copy Website URL to Clipboard
              </Button>
            </ModalActionList>
          : null}
      </Modal>

      <ModalConfirm
        isVisible={isDeleteConfirmDialogOpen}
        title="Are you sure you want to delete this household?"
        description="Deleting a household can not be undone. Please double check all details."
        onCancelClick={() => setIsDeleteConfirmDialogOpen(false)}
        onConfirmClick={handleDeleteHousehold}
        />
    </>
  );
}
