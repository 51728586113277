import React, { useState } from 'react';
import { Button, Table, TableHead, TableBody, TableRow, TableCell, Checkbox, RSVPBadge } from "../widgets";
import FormResponseAnswer from './FormResponseAnswer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faAt, faCircleExclamation, faCheck } from '@fortawesome/free-solid-svg-icons'

const GuestListGuestsTable = ({selectedHouseholdDocIds, households, columns, onToggleAllSelected, onToggleSelectedHousehold, onHouseholdClick}) => {
  const [columnConfigAnchorEl, setColumnConfigAnchorEl] = useState(null);
  const isColumnConfigOpen = Boolean(columnConfigAnchorEl);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={Object.keys(selectedHouseholdDocIds).length === households.length}
                onChange={onToggleAllSelected}
              />
            </TableCell>
            <TableCell>Household</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Contact Details</TableCell>
            <TableCell>Contacted?</TableCell>
            {columns?.map((column) => {
              if (column.label === "RSVP?") return;
              if (column.visible) {
                return <TableCell>{column.label}</TableCell>
              } else {
                return null;
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {households.map((household) => (
            <TableRow
              key={household.docId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <Checkbox
                  checked={household.docId in selectedHouseholdDocIds}
                  onChange={(e) => onToggleSelectedHousehold(e, household.docId)}
                />
              </TableCell>
              <TableCell>
                {household.guests.map((guest, idx) => {
                  return (
                    <Button
                      format="link"
                      key={idx}
                      onClick={() => onHouseholdClick(household.docId)}
                      >
                      {`${guest.firstName} ${guest.lastName}`.trim() || `Guest ${idx}`}
                    </Button>
                  );
                })}
              </TableCell>
              <TableCell>
              {columns?.map((column) => {
                if (column.label === "RSVP?") {
                  const response = household.formResponses?.find((response) => response.questionId === column.questionId);
                  return (
                    <div>
                      {household.guests.map((guest) => {
                        return (
                          <RSVPBadge
                            guestResponse={response?.guestAnswers?.[guest.id]}
                            />
                        );
                      })}
                    </div>
                  )
                }
              })}
              </TableCell>
              <TableCell>
                {household.guests.find((guest) => guest.email)
                  ? <span style={{ display: "inline-block", width: "24px", color: "#8c8c8c" }}><FontAwesomeIcon icon={faPhone} size="lg" /></span>
                  : <span style={{ display: "inline-block", width: "24px", color: "#8c8c8c" }} />}

                {household.guests.find((guest) => guest.phone)
                  ? <span style={{ display: "inline-block", width: "24px", color: "#8c8c8c" }}><FontAwesomeIcon icon={faAt} size="lg" /></span>
                  : <span style={{ display: "inline-block", width: "24px", color: "#8c8c8c" }} />}
              </TableCell>
              <TableCell>
                {household.hasMailBeenSent || household.hasMessageBeenSent
                  ? <span style={{ display: "inline-block", width: "24px", color: "#8c8c8c" }}><FontAwesomeIcon icon={faCheck} size="lg" /></span>
                  : null}
                {household.hasMailWithUnacknowledgedErrors || household.hasMessagesWithUnacknowledgedErrors
                  ? <span style={{ display: "inline-block", width: "24px", color: "#8c8c8c" }}><FontAwesomeIcon icon={faCircleExclamation} size="lg" /></span>
                  : null}
              </TableCell>
              {
                columns?.map((column) => {
                  if (column.label === "RSVP?") return;
                  if (!column.visible) return null;
                  if (!column.type) return null;

                  const response = household.formResponses?.find((response) => response.questionId === column.questionId);

                  return (
                    <TableCell key={column.questionId}>
                      {response
                        ? <FormResponseAnswer
                            type={column.type}
                            cardinality={column.cardinality}
                            guests={household.guests}
                            answer={response.answer}
                            guestAnswers={response.guestAnswers}
                            format="table"
                          />
                        : null}
                    </TableCell>
                  );
                })
              }
            </TableRow >
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default GuestListGuestsTable;
