import * as React from 'react';
import { withAuthentication } from "./AuthenticatedPage";

function Home() {
  return (
    <>
      <h1>Home</h1>
      <h2>Nothing to see here... yet!</h2>
    </>
  );
}

export default withAuthentication(Home);
