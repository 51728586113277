import React, { useState, useEffect, useMemo } from "react";
import { withAuthentication } from "./AuthenticatedPage";
import GuestList from "../components/guests/GuestList";
import { db } from "../firebase";
import { query, collection, getDocs, doc, addDoc, updateDoc, deleteDoc, where } from "firebase/firestore";
import { Loading } from "../components/widgets";
import { reauthenticateWithCredential } from "firebase/auth";
import GuestStats from "./GuestStats";

const getRecentActivity = (households) => {
  if (!households) return;

  const householdActivity = {};
  households.forEach((household) => {
    household.formResponses?.forEach((formResponse) => {
      // we key by household and date time so that we group responses that were submitted at the
      // exact same time as one response == one question
      const key = `${household.docId}-${formResponse.responseDateTime}`
      householdActivity[key] = {
        householdDocId: household.docId,
        household: household.guests.map((guest) => `${guest.firstName} ${guest.lastName}`.trim()).join(', '),
        description: "Submitted a form",
        dateTime: formResponse.responseDateTime?.toDate(),
      };
    });
  });

  const activities = Object.values(householdActivity);
  activities.sort((a, b) => {
    if (a.dateTime === b.dateTime) {
      return 0;
    } else if (a.dateTime === undefined) {
      return 1;
    } else if (b.dateTime === undefined) {
      return -1;
    } else {
      return a.dateTime > b.dateTime ? -1 : 1;
    }
  });

  return activities;
}

function Guests({ user, wedding, updateWedding }) {
  const [households, setHouseholds] = useState();
  const [formQuestions, setFormQuestions] = useState();
  const [mail, setMail] = useState();
  const [messages, setMessages] = useState();

  const addHousehold = async (docId, guests) => {
    await addDoc(collection(db, "weddings", wedding?.id, "households"), {
      guests: guests,
    });
  }

  const updateHousehold = async (docId, guests) => {
    await updateDoc(doc(db, "weddings", wedding?.id, "households", docId), {
      guests: guests,
    });
  }

  const deleteHousehold = async (docId) => {
    await deleteDoc(doc(db, "weddings", wedding?.id, "households", docId));
  }

  const fetchHouseholds = async () => {
    if (!wedding) return;

    try {
      const q = query(collection(db, "weddings", wedding?.id, "households"));
      const doc = await getDocs(q);

      let nextHouseholds = [];
      doc.docs.forEach(async (doc) => {
        nextHouseholds.push({
          docId: doc.id,
          ...doc.data(),
        });
      });

      setHouseholds(nextHouseholds);
    } catch (err) {
      console.error(err);
      alert("An error occurred while fetching households");
    }
  };

  const fetchFormQuestions = async () => {
    if (!wedding) return;

    try {
      const q = query(collection(db, "weddings", wedding?.id, "forms"));
      const forms = await getDocs(q);

      let nextFormQuestions = [];
      forms.docs.forEach(async (form) => {
        form.data().questions.forEach(async (question) => {
          nextFormQuestions.push({
            formId: form.id,
            questionId: question.id,
            label: question.label,
            type: question.type,
            cardinality: question.cardinality,
            icon: question.icon,
            iconColor: question.iconColor,
            question: question.question,
          });
        });
      });

      setFormQuestions(nextFormQuestions);
    } catch (err) {
      console.error(err);
      alert("An error occurred while fetching households");
    }
  }

  const fetchMail = async () => {
    if (!wedding) return;

    try {
      const q = query(collection(db, "mail"), where("weddingRef", "==", wedding.id));
      const doc = await getDocs(q);

      let nextMail = [];
      doc.docs.forEach(async (doc) => {
        nextMail.push({
          docId: doc.id,
          ...doc.data(),
        });
      });

      setMail(nextMail);
    } catch (err) {
      console.error(err);
      alert("An error occurred while fetching mail");
    }
  }

  const fetchMessages = async () => {
    if (!wedding) return;

    try {
      const q = query(collection(db, "messages"), where("weddingRef", "==", wedding.id));
      const doc = await getDocs(q);

      let nextMessages = [];
      doc.docs.forEach(async (doc) => {
        nextMessages.push({
          docId: doc.id,
          ...doc.data(),
        });
      });

      setMessages(nextMessages);
    } catch (err) {
      console.error(err);
      alert("An error occurred while fetching messages");
    }
  }

  useEffect(() => {
    fetchHouseholds();
    fetchFormQuestions();
    fetchMail();
    fetchMessages();
  }, [wedding]);

  const updateColumnConfig = async (questionId, visible) => {
    const visibleColumns = wedding.configuration.guests.visibleColumns;
    let nextVisibleColumns;
    if (visible && visibleColumns.indexOf(questionId) < 0) {
      nextVisibleColumns = [...visibleColumns, questionId];
    } else if (!visible) {
      nextVisibleColumns = [...visibleColumns]
      nextVisibleColumns.splice(visibleColumns.indexOf(questionId), 1);
    }

    const nextConfiguration = {
      ...wedding.configuration,
      guests: {
        ...wedding.configuration.guests,
        visibleColumns: nextVisibleColumns,
      }
    }

    await updateWedding('configuration', nextConfiguration);
  }

  const updateTotalsConfig = async (questionId, visible, filter) => {
    const visibleTotals = wedding.configuration.guests.visibleTotals;
    let nextVisibleTotals;
    const existingIndex = visibleTotals.findIndex((total) => total.questionId === questionId && total.filter === filter);
    if (visible && existingIndex < 0) {
      nextVisibleTotals = [...visibleTotals, { questionId: questionId, filter: filter }];
    } else if (!visible) {
      nextVisibleTotals = [...visibleTotals]
      nextVisibleTotals.splice(existingIndex, 1);
    }

    const nextConfiguration = {
      ...wedding.configuration,
      guests: {
        ...wedding.configuration.guests,
        visibleTotals: nextVisibleTotals,
      }
    }

    await updateWedding('configuration', nextConfiguration);
  }

  const handleOnMessageErrorAcknowledged = async (messageType, docId) => {
    let collection;
    if (messageType === "email") {
      collection = "mail";
    } else if (messageType === "sms") {
      collection = "messages";
    } else {
      alert(`Error: An unknown message type of - ${messageType} - was acknowledged.`);
    }

    try {
      const messageDocRef = doc(db, collection, docId);
      await updateDoc(messageDocRef, {
        "errorAcknowledged": true
      });
    } catch (err) {
      console.error(err);
      alert("An error occurred while acknowledging message error.");
    }

    if (messageType === "email") {
      setMail((prevMail) => {
        const nextMail = [];
        prevMail.forEach((mail) => {
          if (mail.docId === docId) {
            mail.errorAcknowledged = true;
          }
          nextMail.push(mail);
        });
        return nextMail;
      });
    }

    if (messageType === "sms") {
      setMessages((prevMessages) => {
        const nextMessages = [];
        prevMessages.forEach((message) => {
          if (message.docId === docId) {
            message.errorAcknowledged = true;
          }
          nextMessages.push(message);
        });
        return nextMessages;
      });
    }
  }

  const recentActivity = useMemo(() => getRecentActivity(households), [households]);

  return (
    <>
      {(user && wedding && households && formQuestions)
        ? <>
            <GuestStats
              households={households}
              statsConfig={wedding.configuration.guests.stats}
              formQuestions={formQuestions}
              />
            <GuestList
              households={households}
              fetchHouseholds={fetchHouseholds}
              addHousehold={addHousehold}
              updateHousehold={updateHousehold}
              deleteHousehold={deleteHousehold}
              websiteUrl={wedding?.websiteUrl}
              columns={formQuestions?.map((question) => {
                return {
                  questionId: question.questionId,
                  label: question.label,
                  visible: wedding.configuration.guests.visibleColumns.indexOf(question.questionId) >= 0,
                  type: question.type,
                  cardinality: question.cardinality,
                  icon: question.icon,
                  iconColor: question.iconColor,
                  question: question.question,
                };
              })}
              setVisibleColumn={updateColumnConfig}
              setVisibleTotals={updateTotalsConfig}
              mail={mail}
              messages={messages}
              onErrorAcknowledge={handleOnMessageErrorAcknowledged}
              recentActivity={recentActivity}
            />
          </>
        : <div style={{flex: 1}}>
            <Loading />
          </div>}
    </>
  );
}

export default withAuthentication(Guests);
