import * as React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faSitemap, faListCheck, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { withAuthentication } from "../../containers/AuthenticatedPage";
import { logout } from "../../firebase";
import styles from "./Nav.module.css";
import userLogo from "./user-logo.png";

function Nav({user, selectedPathname}) {
  console.log(user);
  return (
    <nav className={styles.Menu}>
      <ul>
        <li className={selectedPathname === "/guests" ? styles.MenuOptionSelected : styles.MenuOption}>
          <Link to="/guests">
            <span className={styles.Icon}>
              <FontAwesomeIcon icon={faPeopleGroup} />
            </span>
            <span className={styles.Label}>
              Guests
            </span>
          </Link>
        </li>
        <li className={selectedPathname === "/website" ? styles.MenuOptionSelected : styles.MenuOption}>
          <Link to="/website">
            <span className={styles.Icon}>
              <FontAwesomeIcon icon={faSitemap} />
            </span>
            <span className={styles.Label}>
              Website
            </span>
          </Link>
        </li>
        <li className={selectedPathname === "/forms" ? styles.MenuOptionSelected : styles.MenuOption}>
          <Link to="/forms">
            <span className={styles.Icon}>
              <FontAwesomeIcon icon={faListCheck} />
            </span>
            <span className={styles.Label}>
              Forms
            </span>
          </Link>
        </li>
      </ul>
      <div className={styles.UserTile}>
        <div className={styles.UserLogo}>
          <img  className={styles.UserLogo} src={userLogo} />
        </div>
        <div className={styles.UserDetails}>
          <div className={styles.UserName}>{user?.displayName}</div>
          <div className={styles.UserEmail}>{user?.email}</div>
        </div>
        <div className={styles.UserMenuHandle} onClick={logout}>
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </div>
      </div>
    </nav>
  );
}

export default withAuthentication(Nav);
