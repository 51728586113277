// Config and function here taken from: https://blog.logrocket.com/user-authentication-firebase-react-apps/
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCLcFUlOXM_zzO3upXjaS5JSrANo1omcck",
  authDomain: "wedding-website-ed3da.firebaseapp.com",
  projectId: "wedding-website-ed3da",
  storageBucket: "wedding-website-ed3da.appspot.com",
  messagingSenderId: "933983615098",
  appId: "1:933983615098:web:ed6592075bd65534a323c3",
  measurementId: "G-84HXM4BY9Q"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);


const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  storage,
  logInWithEmailAndPassword,
  logout,
};
