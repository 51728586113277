import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from "./TextInput.module.css";

export const TextInput = ({placeholder, value, onChange, icon, label, containerClassName}) => {
  return (
    <div className={containerClassName ? [styles.TextInputContainer, containerClassName] : styles.TextInputContainer}>
      {label
        ? <label className={styles.Label}>{label}</label>
        : null}
      {icon
        ? <span className={styles.Icon}><FontAwesomeIcon icon={icon} /></span>
        : null}
      <input
        className={icon ? styles.TextInputWithIcon : styles.TextInput}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        />
    </div>
  );
}
