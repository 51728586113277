import * as React from "react";
import { withAuthentication } from "./AuthenticatedPage";

function Forms() {
  return (
    <>
      <h1>Forms</h1>
      <h2>Nothing to see here... yet!</h2>
    </>
  );
}

export default withAuthentication(Forms);
